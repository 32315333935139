import React from 'react';
import PropTypes from 'prop-types';

import FullscreenBanner from '../../Banner/FullscreenBanner';
import InfoSection from '../../InfoSection/InfoSection';
import { spacing } from './HomePageTemplate.module.scss';

const populateInfoSections = (infoSections) => {
  const infoSectionList = [];

  for (const obj of infoSections) {
    if (obj.title && obj.body) {
      infoSectionList.push(
        <InfoSection
          title={obj.title}
          body={obj.body}
          alignment={obj.alignment}
          featuredImage={obj.featuredImage}
          key={infoSectionList.length}
        />
      );
    }
  }

  return infoSectionList;
};

const HomePageTemplate = ({ bannerImg, infoSections }) => (
  <main>
    <FullscreenBanner bannerImg={bannerImg} />
    <div className={`has-background-white ${spacing}`}>
      <div className="container">{populateInfoSections(infoSections)}</div>
    </div>
  </main>
);

HomePageTemplate.propTypes = {
  bannerImg: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  infoSections: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      body: PropTypes.string.isRequired,
      alignment: PropTypes.oneOf(['right', 'left']),
      featuredImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    }).isRequired
  ).isRequired
};

export default HomePageTemplate;
