import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import '../main.scss';
import { getImage } from 'gatsby-plugin-image';
import Wrapper from '../components/Wrapper/Wrapper';
import HomePageTemplate from '../components/Templates/Home/HomePageTemplate';
import SEO from '../components/SEO';

const HomePage = ({ data }) => {
  const { bannerImg, infoSections } = data.markdownRemark.frontmatter;

  return (
    <Wrapper>
      <SEO />
      <HomePageTemplate bannerImg={getImage(bannerImg)} infoSections={infoSections} />
    </Wrapper>
  );
};

HomePage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object.isRequired
  }).isRequired
};

export default HomePage;

export const query = graphql`
  query HomePageQuery {
    markdownRemark(frontmatter: { templateKey: { eq: "HomePage" } }) {
      frontmatter {
        bannerImg {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
        infoSections {
          title
          body
          alignment
          featuredImage {
            childImageSharp {
              gatsbyImageData(aspectRatio: 1, placeholder: BLURRED, quality: 80)
            }
          }
        }
      }
    }
  }
`;
