import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

import { container, reverseColumns } from './InfoSection.module.scss';
import CompatibleImage from '../utils/CompatibleImage';

const InfoSection = ({ title, body, alignment, featuredImage }) => {
  const [ref, inView] = useInView({ threshold: 0.5, triggerOnce: true });
  const right = {
    hidden: { opacity: 0, x: 150 },
    visible: { opacity: 1, x: 0, transition: { duration: 1.5, ease: 'easeIn' } }
  };
  const left = {
    hidden: { opacity: 0, x: -150 },
    visible: { opacity: 1, x: 0, transition: { duration: 1.5, ease: 'easeIn' } }
  };

  return (
    <section className={`container ${container}`}>
      <div className={`columns ${alignment === 'right' ? reverseColumns : ''}`}>
        {featuredImage && (
          <motion.figure
            animate={inView ? 'visible' : 'hidden'}
            variants={alignment === 'right' ? right : left}
            ref={ref}
            className="column image"
          >
            <CompatibleImage image={featuredImage} />
          </motion.figure>
        )}
        <motion.div
          animate={inView ? 'visible' : 'hidden'}
          variants={alignment === 'right' ? left : right}
          ref={ref}
          className="column"
        >
          <h1 className={`title is-1 ${alignment === 'right' ? `has-text-right-tablet` : ''} has-text-centered-mobile`}>
            {title}
          </h1>
          <div className="content">
            <p>{body}</p>
          </div>
        </motion.div>
      </div>
    </section>
  );
};

InfoSection.defaultProps = {
  featuredImage: '',
  alignment: 'right'
};

InfoSection.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  alignment: PropTypes.oneOf(['right', 'left']),
  featuredImage: PropTypes.oneOfType([PropTypes.object.isRequired, PropTypes.string])
};

export default InfoSection;
