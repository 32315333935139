import React from 'react';
import PropTypes from 'prop-types';
import { motion, useTransform, useViewportScroll } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';

import { GatsbyImage } from 'gatsby-plugin-image';
import { wrapper, image, imageString, bannerSection, icon } from './FullscreenBanner.module.scss';

const FullscreenBanner = ({ bannerImg }) => {
  const variants = {
    move: {
      y: '-3vh',
      transition: {
        repeat: Infinity,
        duration: 2,
        repeatType: 'mirror',
        when: 'afterChildren'
      }
    }
  };
  const { scrollYProgress } = useViewportScroll();
  const opacity = useTransform(scrollYProgress, [0, 0.1], [1, 0]);

  // intended to work with NetlifyCMS
  const banner =
    typeof bannerImg === 'string' ? (
      <div className={imageString} style={{ backgroundImage: `url(${bannerImg})` }} />
    ) : (
      <GatsbyImage className={wrapper} imgClassName={image} image={bannerImg} alt="" />
    );

  return (
    <div className={bannerSection}>
      {banner}
      <motion.span variants={variants} animate="move" className={`icon is-large ${icon} secondary is-hidden-mobile`}>
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 2 }} style={{ opacity }}>
          <FontAwesomeIcon icon={faAngleDown} size="3x" />
        </motion.div>
      </motion.span>
    </div>
  );
};

FullscreenBanner.propTypes = {
  bannerImg: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired
};

export default FullscreenBanner;
