import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

const SEO = ({ title, description, image, article }) => {
  const { site, allImageSharp } = useStaticQuery(graphql`
    query SEO {
      site {
        siteMetadata {
          title
          description
          siteUrl
        }
      }
      allImageSharp(filter: { original: { src: { regex: "/logo-square/" } } }) {
        edges {
          node {
            gatsbyImageData(aspectRatio: 1)
          }
        }
      }
    }
  `);

  const { title: defaultTitle, description: defaultDescription, siteUrl } = site.siteMetadata;
  const logo = allImageSharp.edges[0].node.gatsbyImageData.images.fallback.src;
  const baseUrl = typeof window !== 'undefined' ? window.location.origin : siteUrl;
  const pathname = typeof window !== 'undefined' ? window.location.pathname : '';
  const logoImg = `${baseUrl}${logo}`;

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: image ? `${baseUrl}${image}` : logoImg,
    url: baseUrl ? new URL(pathname, baseUrl) : '',
    article
  };

  const baseSchema = [
    { '@context': 'http://schema.org', '@type': 'WebSite', url: seo.url, name: seo.title, alternateName: defaultTitle }
  ];

  const schema = article
    ? [
        ...baseSchema,
        {
          '@context': 'http://schema.org',
          '@type': 'BreadcrumbList',
          itemListElement: [
            {
              '@type': 'ListItem',
              position: 1,
              item: {
                '@id': seo.url,
                name: seo.title,
                image: seo.image
              }
            }
          ]
        },
        {
          '@context': 'http://schema.org',
          '@type': 'BlogPosting',
          url: seo.url,
          name: seo.title,
          alternateName: defaultTitle,
          headline: seo.title,
          image: {
            '@type': 'ImageObject',
            url: seo.image
          },
          description: seo.description,
          publisher: {
            '@type': 'Organization',
            url: baseUrl,
            logo: {
              '@type': 'ImageObject',
              url: logoImg
            },
            name: defaultTitle
          },
          mainEntityOfPage: {
            '@type': 'WebSite',
            '@id': baseUrl
          }
        }
      ]
    : baseSchema;

  return (
    <Helmet>
      {/* General tags */}
      <title>{seo.title}</title>
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      <link rel="canonical" href={seo.url} />
      {/* OpenGraph tags */}
      <meta property="og:type" content={seo.article ? 'article' : 'website'} />
      <meta property="og:title" content={seo.title} />
      <meta property="og:description" content={seo.description} />
      <meta property="og:image" content={seo.image} />
      <meta property="og:url" content={seo.url} />
      {/* Twitter Card tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:image" content={seo.image} />
      {/* Schema.org tags */}
      <script type="application/ld+json">{JSON.stringify(schema)}</script>
    </Helmet>
  );
};

SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  article: false
};

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  article: PropTypes.bool
};

export default SEO;
