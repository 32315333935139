import React from 'react';
import PropTypes from 'prop-types';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';

/**
 * * This utility Component allows images to work on the website as well as the cms
 * @param image - either a Gatsby Image object or an image path string
 */
const CompatibleImage = ({ image }) => {
  if (typeof image === 'string') {
    return <img src={image} alt="" />;
  }

  const img = getImage(image);
  return <GatsbyImage image={img} alt="" />;
};

CompatibleImage.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object.isRequired, PropTypes.string]).isRequired
};

export default CompatibleImage;
